import SourceFlowText from "@sourceflow-uk/sourceflowtext";
import { Container, Row, Col, Nav, NavItem } from "reactstrap";
import styles from "./styles.module.scss";
import SourceFlowLogo from "/public/site-assets/svg/sourceflow-white-logo.svg";
import Logo from "/public/site-assets/svg/logo-short.svg";
import Link from "next/link";
import SocialLinks from "/components/SocialLinks";

export default function Footer() {
  return (
    <>
      <footer className={`${styles.root} mb-5`}>
        <Container className="main">
          <div className="wrapper p-5">
            <Row className="mb-md-5 pt-4 pb-4">
              <Col lg={8}>
                <Link href="/">
                  <a
                    className="text-decoration-none d-inline-block logo"
                    title="gap personnel"
                    aria-label="gap personnel"
                  >
                    <Logo />
                  </a>
                </Link>
              </Col>
              <Col lg={4} className="position-relative circle-wrapper">
                <span className="circle position-absolute"></span>
                <span className="circle position-absolute"></span>
                <span className="circle position-absolute"></span>
              </Col>
            </Row>
            <Row className="mb-5">
              <Col xs={12} lg={5} className="pe-lg-5 mb-4 mb-lg-0">
                <p className="fs-4 pe-lg-5 text-white lead-text">
                  Real people, real solutions
                </p>
              </Col>
              <Col xs={6} lg={2}>
                <Nav className="d-block navigation">
                  <NavItem>
                    <Link href="/">
                      <a className="text-decoration-none fs-5">
                        <small>Home</small>
                      </a>
                    </Link>
                  </NavItem>
                  <NavItem>
                    <Link href="/jobs/">
                      <a className="text-decoration-none fs-5">
                        <small>Jobs</small>
                      </a>
                    </Link>
                  </NavItem>
                  <NavItem>
                    <Link href="/job-seekers/">
                      <a className="text-decoration-none fs-5">
                        <small>Candidates</small>
                      </a>
                    </Link>
                  </NavItem>
                  <NavItem>
                    <Link href="/who-we-are/">
                      <a className="text-decoration-none fs-5">
                        <small>Employers</small>
                      </a>
                    </Link>
                  </NavItem>
                  <NavItem>
                    <Link href="/job-seekers-specialisms/">
                      <a className="text-decoration-none fs-5">
                        <small>Sectors</small>
                      </a>
                    </Link>
                  </NavItem>
                </Nav>
              </Col>
              <Col xs={6} lg={2}>
                <Nav className="d-block navigation">
                  <NavItem>
                    <Link href="/job-seekers-get-in-touch/">
                      <a className="text-decoration-none fs-5">
                        <small>Our branches</small>
                      </a>
                    </Link>
                  </NavItem>
                  <NavItem>
                    <Link href="/who-we-are/">
                      <a className="text-decoration-none fs-5">
                        <small>About us</small>
                      </a>
                    </Link>
                  </NavItem>
                  <NavItem>
                    <Link href="/gap-personnel-careers/">
                      <a className="text-decoration-none fs-5">
                        <small>Join us</small>
                      </a>
                    </Link>
                  </NavItem>
                  <NavItem>
                    <Link href="/job-seekers-get-in-touch/">
                      <a className="text-decoration-none fs-5">
                        <small>Contact us</small>
                      </a>
                    </Link>
                  </NavItem>
                </Nav>
              </Col>
              <Col lg={3}>
                <SocialLinks customClass={`footer-section`} />
              </Col>
            </Row>
          </div>
        </Container>
        <Container className="copyrights">
          <div className="wrapper pt-4 pb-4 ps-5 pe-5">
            <div className="d-flex flex-wrap justify-content-center justify-content-lg-between align-items-center mt-3 mb-3">
              <div className="d-flex align-items-center flex-wrap justify-content-start">
                {/* <p className="fs-6 mb-0 text-center mb-4 mb-lg-0 me-lg-4 text-white"><small>© {new Date().getFullYear()} Driving Force Recruitment</small></p> */}
                <Nav className="justify-content-start mb-4 mb-lg-0">
                  <NavItem className="text-center">
                    <small>© {new Date().getFullYear()} Gap Personnel</small>
                  </NavItem>
                  <NavItem className="text-center">
                    <Link href="/terms-and-conditions/">
                      <a className="text-decoration-none fs-6">
                        <small>Terms & Conditions</small>
                      </a>
                    </Link>
                  </NavItem>
                  <NavItem className="text-center">
                    <Link href="/privacy-policy/">
                      <a className="text-decoration-none fs-6">
                        <small>Privacy Policy</small>
                      </a>
                    </Link>
                  </NavItem>
                  <NavItem className="text-center">
                    <Link href="/cookie-policy/">
                      <a className="text-decoration-none fs-6">
                        <small>Cookie Policy</small>
                      </a>
                    </Link>
                  </NavItem>
                  <NavItem className="text-center">
                    <Link href="/complaints-policy/">
                      <a className="text-decoration-none fs-6">
                        <small>Complaints Policy</small>
                      </a>
                    </Link>
                  </NavItem>
                  <NavItem className="text-center">
                    <Link href="/csr-policy/">
                      <a className="text-decoration-none fs-6">
                        <small>CSR Policy</small>
                      </a>
                    </Link>
                  </NavItem>
                  <NavItem className="text-center">
                    <Link href="/employment-fraud/">
                      <a className="text-decoration-none fs-6">
                        <small>Employment Fraud</small>
                      </a>
                    </Link>
                  </NavItem>
                  <NavItem className="text-center">
                    <Link href="/equal-opportunities/">
                      <a className="text-decoration-none fs-6">
                        <small>Equal Opportunities</small>
                      </a>
                    </Link>
                  </NavItem>
                  <NavItem className="text-center">
                    <Link href="/site-assets/pdf/uk-tax-strategy.pdf">
                      <a className="text-decoration-none fs-6" target="_blank">
                        <small>UK Tax Strategy</small>
                      </a>
                    </Link>
                  </NavItem>
                  <NavItem className="text-center">
                    <Link href="/site-assets/pdf/gender-pay-gap-report-2024.pdf">
                      <a className="text-decoration-none fs-6" target="_blank">
                        <small>Gender Pay Gap Report</small>
                      </a>
                    </Link>
                  </NavItem>
                  <NavItem className="text-center">
                    <Link href="/site-assets/pdf/modern-slavery-policy-statement-V7.pdf">
                      <a className="text-decoration-none fs-6" target="_blank">
                        <small>Modern Slavery Statement</small>
                      </a>
                    </Link>
                  </NavItem>
                </Nav>
              </div>
              <div className="w-100 fs-6 m-0 sourceflow d-flex flex-wrap align-items-end text-white justify-content-end justify-content-md-end mt-4">
                <div>
                  <small>Site by</small>
                  <Link href="https://www.sourceflow.co.uk/">
                    <a target="_blank" title="SourceFlow" aria-label="SourceFlow">
                      <SourceFlowLogo />
                    </a>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </Container>
        <script type="text/javascript"
          dangerouslySetInnerHTML={{
            __html: `
        var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
        (function(){
        var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
        s1.async=true;
        s1.src='https://embed.tawk.to/5d3866b56d8083122839cead/1hboa1lsf';
        s1.charset='UTF-8';
        s1.setAttribute('crossorigin','*');
        s0.parentNode.insertBefore(s1,s0);
        })();
      `,
          }}
        />
      </footer>
    </>
  );
}