import Head from 'next/head'
// import Header from './MainHeader';
import Footer from './MainFooter';
import Link from 'next/link';
import Script from "next/script";
export default function layout({ children }) {
    return (
        <>
            <Head>
                <meta name="viewport" content="initial-scale=1.0, width=device-width" />
                <meta name="description" content="" />
            </Head>

            {/* <Header /> */}
            <main>{children}</main>
            <Footer />
        </>
    )
}