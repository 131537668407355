import {
    Container,
    Row,
    Col,
    List
} from "reactstrap";
import styles from './styles.module.scss';
import Link from 'next/link';
import Linkedin from '../../public/site-assets/svg/linkedin.svg'
import Facebook from '../../public/site-assets/svg/facebook.svg'
import Twitter from '../../public/site-assets/svg/twitter.svg'
import Instagram from '../../public/site-assets/svg/instagram.svg'

export default function SocialLinks({
    customClass = ""
}) {
    return (
        <div className={`${styles.root} ${customClass} social-links`}>
            <div className="social-links-wrapper">
                <Link href="https://www.linkedin.com/company/gap-personnel/">
                    <a className="text-decoration-none" target="_blank" rel="noreferrer noopener" aria-label="Follow us on Linkedin">
                        <Linkedin />
                    </a>
                </Link>
                <Link href="https://twitter.com/gappersonnel">
                    <a className="text-decoration-none" target="_blank" rel="noreferrer noopener" aria-label="Follow us on Twitter">
                        <Twitter />
                    </a>
                </Link>
                <Link href="https://www.facebook.com/gap.personnel">
                    <a className="text-decoration-none" target="_blank" rel="noreferrer noopener" aria-label="Follow us on Facebook">
                        <Facebook />
                    </a>
                </Link>
                <Link href="https://www.instagram.com/gappersonnelgroup/">
                    <a className="text-decoration-none" target="_blank" rel="noreferrer noopener" aria-label="Follow us on Instagram">
                        <Instagram />
                    </a>
                </Link>
            </div>
        </div>
    )
}