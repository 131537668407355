import Head from 'next/head'
import '../styles/variables.scss'
import '../styles/globals.scss'
import '../styles/layout.scss'
import Layout from '../components/layout'
import 'bootstrap/dist/css/bootstrap.min.css';
import SourceFlowHead from "@sourceflow-uk/sourceflow-head";
import metaObject from "../.sourceflow/metadata.json";
import CookieConsent from "react-cookie-consent";
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';

function MyApp({ Component, pageProps }) {
  return (
    <Layout>
      <SourceFlowHead metaObject={metaObject} />
      <Component {...pageProps} />
      <CookieConsent
        location="bottom"
        buttonText="Accept cookies"
        style={{
          background: "#fff",
          color: "#423C58",
          width: "95%",
          maxWidth: "45rem",
          border: "none",
          left: "0",
          bottom: "0",
          left: "1rem",
          margin: "0 0 0 2rem",
          left: "inherit",
          fontSize: "1rem",
          padding: "0 0 0.5rem 0.5rem",
          boxShadow: "0 0 10px rgba(0,0,0,0.2)",
        }}
        linkStyle={{ color: "#fff" }}
        buttonStyle={{
          color: "#fff",
          background: "#582669",
          border: "solid 1px #423C58",
          borderRadius: "5px",
          fontSize: "0.8rem",
        }}
        expires={150}
      >
        We use cookies to provide the best possible experience for our users.
        They help us provide essential functionality and improve site
        performance, and allow us to offer a more personalised experience when
        using the site.
        {/* For more information:{" "}
        <Link href="/cookie-policy">
          <a style={{ color: "#fff" }}>Сookie policy</a>
        </Link>{" "} */}
      </CookieConsent>
      <TawkMessengerReact
        propertyId='5d3866b56d8083122839cead'
        widgetId='1hboa1lsf'
      />
    </Layout>)
}

export default MyApp
